<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo />
        <!-- End Header Area -->
        <!-- Start Slider Area  -->
        <div class="rn-section-gapDoubleTop bg_color--1">
            <v-container>
                <v-row>
                    <v-col lg="12">
                        <div class="section-title text-center mb--20 mb_sm--0">
                            <h2 class="heading-title">Travel</h2>
                            <p>
                                As we know many of you will be travelling to come to our wedding,
                                <br />
                                we have compiled a list of travel options to hopefully assist in making your trip a bit smoother.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="rn-section-gapBottom about-area">
            <div class="about-wrapper">
                <Flights>
                    <img slot="thum-img"
                         class="w-100"
                         src="../../assets/images/travel/Plane.jpg"
                         alt="About Images" />
                </Flights>
            </div>
        </div>

        <div class="rn-section-gapBottom about-area">
            <div class="about-wrapper">
                <GullBus>
                    <img slot="thum-img"
                         class="w-100"
                         src="../../assets/images/travel/GullBus.jpg"
                         alt="About Images" />
                </GullBus>
            </div>
        </div>

        <div class="rn-section-gapBottom about-area">
            <div class="about-wrapper">
                <SkyBus>
                    <img slot="thum-img"
                         class="w-100"
                         src="../../assets/images/travel/Skybus.jpg"
                         alt="About Images" />
                </SkyBus>
            </div>
        </div>

        <div class="rn-section-gapBottom about-area">
            <div class="about-wrapper">
                <MyBus>
                    <img slot="thum-img"
                         class="w-100"
                         src="../../assets/images/travel/MyBus.jpg"
                         alt="About Images" />
                </MyBus>
            </div>
        </div>

        <div class="rn-section-gapBottom about-area">
            <div class="about-wrapper">
                <PublicTransport>
                    <img slot="thum-img"
                         class="w-100"
                         src="../../assets/images/travel/Myki.png"
                         alt="About Images" />
                </PublicTransport>
            </div>
        </div>

        <div class="rn-section-gapBottom about-area">
            <div class="about-wrapper">
                <HireCar>
                    <img slot="thum-img"
                         class="w-100"
                         src="../../assets/images/travel/HireCar.jpg"
                         alt="About Images" />
                </HireCar>
            </div>
        </div>

        <!-- End Slider Area -->
        <!-- Start About Area  -->

        <FooterTwo />
    </div>
</template>

<script>
  import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
  import InvitationDetails from "../../components/invitation/InvitationDetails";
  import PortfolioTwo from "../../components/portfolio/PortfolioFour";
  import Flights from "../../components/travel/Flights";
  import SkyBus from "../../components/travel/SkyBus";
  import MyBus from "../../components/travel/MyBus";
  import GullBus from "../../components/travel/GullBus";
  import HireCar from "../../components/travel/HireCar";
  import PublicTransport from "../../components/travel/PublicTransport";
  import FooterTwo from "../../components/footer/FooterTwo";

  export default {
    components: {
      HeaderOnePageTwo,
      InvitationDetails,
      PortfolioTwo,
      SkyBus,
      MyBus,
      HireCar,
      GullBus,
      PublicTransport,
      Flights,
      FooterTwo
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss">
  .feather-menu {
    color: #1d1d24;
  }
</style>
